import React, { useEffect, useState } from "react";
import styles from "./FormLogin.module.css";
import { useNavigate } from "react-router-dom";
import Typography from "../../atoms/Typography/Typography";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import Input from "../../atoms/Input/Input";
import Icon from "../../atoms/Icon/Icon";
import PageSeperator from "../../molecules/PageSeperator/PageSeperator";
import LoginIcons from "../../molecules/LoginIcons/LoginIcons";
import Button from "../../atoms/Button/Button";
import { userLogin, forgotPassword } from "../../../apiHelpers";
import { useTranslation } from "react-i18next";
import ModalNotification from "../../molecules/ModalNotification/ModalNotification";
import ForgotPasswordForm from "../../templates/ForgotPasswordForm/ForgotPasswordForm";
import { useAuth } from "../../../AthContext";
import AppleLogin from "react-apple-login";

const FormLogin = ({
  togglePage,
  onLogin,
  setShowLoginPage,
  setShowAvatarInput,
  setSocialRegister,
  setEmailFromSocial,
  setPasswordFromSocial,
  tokenFromGoogle,
  setTokenFromGoogle,
  setSocialLoginType,
  socialLoginType,
}) => {
  const [submitFormErrorOccurred, setSubmitFormErrorOccurred] = useState(false);
  const [submitFormErrorMessage, setSubmitFormErrorMessage] = useState(null);
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [showModalNotificationSendEmail, setShowModalNotificationSendEmail] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const languageCode = localStorage.getItem("language")?.replace(/"/g, "");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    type: "",
  });
  const { handleAppleLogin } = useAuth();
  const languagePropForPrivacyAndTerms =
    languageCode === "en" ? "En" : languageCode === "de" ? "De" : "";
  const termsAndConditionsLink = `https://api.quizdombattle.com/terms${languagePropForPrivacyAndTerms}`;
  const privacyAndPolicyLink = `https://api.quizdombattle.com/privacy${languagePropForPrivacyAndTerms}`;

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const openAppleListeners = () => {
    document.removeEventListener("AppleIDSignInOnSuccess", handleAuthSuccess);
    document.removeEventListener("AppleIDSignInOnFailure", handleAuthFailure);

    document.addEventListener("AppleIDSignInOnSuccess", (event) => {
      // Handle successful response.
      const code = event.detail.authorization.code;
      const idToken = event.detail.authorization.id_token;
      if (code && idToken) {
        handleAuthSuccess(code, idToken);
      }
    });
    document.addEventListener("AppleIDSignInOnFailure", handleAuthFailure);
  };

  useEffect(() => {
    openAppleListeners();
    return () => {
      document.removeEventListener("AppleIDSignInOnSuccess", handleAuthSuccess);
      document.removeEventListener("AppleIDSignInOnFailure", handleAuthFailure);
    };
  }, []);

  const handleAuthSuccess = (code, idToken) => {
    // Handle the successful authorization response here
    onAppleLoginSuccess(code, idToken);
  };

  // Event listener for Apple authorization failure
  const handleAuthFailure = (event) => {
    console.log("Authorization Failed:", event.detail.error);
  };

  const onAppleLoginSuccess = async (code, idToken) => {
    const token = idToken;
    const id = code;
    try {
      const userData = await handleAppleLogin({ code, idToken }); // Pass both code and id_token

      if (!userData) {
        console.error("Failed to get user data from Apple.");
        return;
      }
      if (userData.errorCode === 2) {
        setSocialLoginType("apple");
        setTokenFromGoogle(token);
        setEmailFromSocial(userData.email);
        setPasswordFromSocial(id);
        setShowLoginPage(false);
        setShowAvatarInput(true);
        setSocialRegister(true);
      } else if (!userData.errorCode) {
        onLogin();
      }
    } catch (error) {
      console.error("Error during Apple login process:", error);
    }
  };

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleLinkClick = () => {
    togglePage();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {
      email:
        formData.email.trim() === ""
          ? "* Email is required."
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
          ? "* Invalid email format."
          : "",
      password:
        formData.password.trim() === ""
          ? "* Password is required."
          : formData.password.length < 6
          ? "* Password must be at least 6 characters."
          : "",
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Validation errors:", errors);
      return;
    }

    // Call the function to get user data with the form data
    try {
      const userData = await userLogin({
        type: "email",
        email: formData.email,
        password: formData.password,
      });
      localStorage.setItem("email", formData.email);
      localStorage.setItem("password", formData.password);
      localStorage.setItem("type", "email");
      // Handle the response data as needed
      onLogin();
      navigate("/", { replace: true });
    } catch (error) {
      // Handle the error
      console.error("Get User Data Error:", error);
      console.log(error.response.data.error);
      const loginErrorMessageResponse = error?.response?.data?.error;
      setSubmitFormErrorMessage(loginErrorMessageResponse);
      setSubmitFormErrorOccurred(true);
    }
  };
  const handleSocialSubmit = async (event) => {
    event.preventDefault();

    const errors = {
      email:
        formData.email.trim() === ""
          ? "* Email is required."
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
          ? "* Invalid email format."
          : "",
      password:
        formData.password.trim() === ""
          ? "* Password is required."
          : formData.password.length < 6
          ? "* Password must be at least 6 characters."
          : "",
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Validation errors:", errors);
      return;
    }

    // Call the function to get user data with the form data
    try {
      const userData = await userLogin({
        type: formData.type,
        email: formData.email,
        password: formData.password,
      });
      localStorage.setItem("email", formData.email);
      localStorage.setItem("password", formData.password);
      localStorage.setItem("type", formData.type);
      // Handle the response data as needed

      // setTimeout(() => {
      //   localStorage.removeItem("userData");
      //   console.log("User data cleared from localStorage");
      // }, userData.expiresIn);
      // For example, navigate to the welcome page after successful login
      // onLogin();
    } catch (error) {
      // Handle the error
      console.error("Get User Data Error:", error);
      console.log(error.response.data.error);
      const loginErrorMessageResponse = error?.response?.data?.error;
      setSubmitFormErrorMessage(loginErrorMessageResponse);
      setSubmitFormErrorOccurred(true);
    }
  };

  const handleClosingModalNotification = () => {
    setSubmitFormErrorOccurred(false);
  };

  const handleForgotPasswordModalClose = () => {
    setShowModalNotificationSendEmail(false);
  };

  const sendForgotPasswordEmail = async () => {
    console.log("forgot password?");
    setShowForgotPasswordForm(true);
  };

  return (
    <>
      {showModalNotificationSendEmail ? (
        <ModalNotification
          imageName="password_envelope"
          heading="Forgot Password"
          text="Please check your email to reset your password."
          onClose={handleForgotPasswordModalClose}
        />
      ) : showForgotPasswordForm ? (
        <ForgotPasswordForm
          setShowForgotPasswordForm={setShowForgotPasswordForm}
          setShowModalNotificationSendEmail={setShowModalNotificationSendEmail}
          setSubmitFormErrorOccurred={setSubmitFormErrorOccurred}
          setSubmitFormErrorMessage={setSubmitFormErrorMessage}
        />
      ) : submitFormErrorOccurred && submitFormErrorMessage ? (
        <ModalNotification
          imageName="reversed_exclamation_mark"
          text={submitFormErrorMessage}
          onClose={handleClosingModalNotification}
        />
      ) : (
        <div className={styles.formContainer}>
          <h2>
            <Typography
              color="colorBlack"
              variant="heading1"
              fontWeight="font700"
            >
              {t("signIn.header")}
            </Typography>
          </h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.formInputsContainer}>
              <div className={styles.inputContainer}>
                <Icon name="envelope_icon" folder="input_icons" />
                <Input
                  type="email"
                  value={formData.email}
                  id="loginEmail"
                  placeholder={t("generic.email")}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
                {formErrors.email && (
                  <Typography
                    additionalClass={styles.errorMessage}
                    variant="caption"
                    fontWeight="font400"
                  >
                    {formErrors.email}
                  </Typography>
                )}
              </div>
              <div className={styles.inputContainer}>
                <Icon name="key_icon" folder="input_icons" />
                <Input
                  type="password"
                  value={formData.password}
                  id="loginPassword"
                  placeholder={t("generic.password")}
                  onChange={(e) =>
                    handleInputChange("password", e.target.value)
                  }
                />
                {formErrors.password && (
                  <Typography
                    additionalClass={styles.errorMessage}
                    variant="caption"
                    fontWeight="font400"
                  >
                    {formErrors.password}
                  </Typography>
                )}
              </div>
            </div>
          </form>
          <div className={styles.linkArea}>
            <Typography variant="body2" fontWeight="font700">
              <Button
                additionalClass={styles.linkButton}
                onClick={sendForgotPasswordEmail}
              >
                {t("generic.forgotPassword")}
              </Button>
            </Typography>
            <PlayButton
              additionalClass={`play-now`}
              primaryText={t("signIn.signInButton")}
              onClick={handleSubmit}
            />
            <Typography fontWeight="font700" variant="body2" color="colorBlack">
              {t("signIn.noAccount")}
              <Button
                additionalClass={styles.linkButton}
                onClick={handleLinkClick}
              >
                {t("signIn.createHere")}
              </Button>
            </Typography>
          </div>
          <div className={styles.termsAndPrivacyArea}>
            {t("signIn.signInQuestionFirstPart")}
            <a
              href={termsAndConditionsLink}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.linkButtonWrapper}
            >
              <Button additionalClass={styles.linkButton}>
                {t("signUpSecondStep.checkBoxSecondPart")}
              </Button>
            </a>
            {t("signIn.signInQuestionSecondPart")}
            <a
              href={privacyAndPolicyLink}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.linkButtonWrapper}
            >
              <Button additionalClass={styles.linkButton}>
                {t("signUpSecondStep.checkBoxFourthPart")}
              </Button>
            </a>
            {t("signIn.signInQuestionLastPart")}
          </div>
          <div className={styles.bottomSection}>
            <PageSeperator />
            <LoginIcons
              setShowLoginPage={setShowLoginPage}
              setShowAvatarInput={setShowAvatarInput}
              setSocialRegister={setSocialRegister}
              setEmailFromSocial={setEmailFromSocial}
              setPasswordFromSocial={setPasswordFromSocial}
              tokenFromGoogle={tokenFromGoogle}
              setTokenFromGoogle={setTokenFromGoogle}
              onLogin={onLogin}
              onAppleLoginSuccess={onAppleLoginSuccess}
              socialLoginType={socialLoginType}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FormLogin;
