// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { socket as startingSocket } from "./services/sockets";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(startingSocket);

  useEffect(() => {
    return () => {};
  }, []);
  const connectSocket = () => {
    //console.log("I connect");
    socket.on("connect", () => {});
  };
  const disconnectSocket = () => {
    //console.log("I disconnect");
    socket.disconnect();
  };

  const emitInit = async () => {
    const access_token = localStorage?.getItem("token");
    const response = await new Promise((resolve) => {
      socket.emit("init", { access_token: access_token }, (response) => {
        resolve(response);
        console.log(response);
      });
    });
    return await response;
  };

  const emitLogout = async () => {
    try {
      const acknowledgement = await new Promise((resolve, reject) => {
        //console.log("try to disconnect");
        // Assuming socket is defined somewhere
        socket.emit("logout", (response) => {
          if (response === "success") {
            resolve(response); // Resolve with "success" if server acknowledges
          } else {
            reject(new Error("Logout failed: " + response)); // Reject with error message if not successful
          }
        });
      });

      if (acknowledgement === "success") {
        console.log("Logout successful!");
        disconnectSocket();
      } // No need for "else" block here as any error would be caught in the try-catch block
    } catch (error) {
      // Handle any errors during the logout process
      console.error("Logout failed:", error);
    }
  };

  // Socket Functions for Special Quiz
  // --------------------------------------------------------------------------------------------
  // Emit for specialQuiz
  const emitNewSpecialQuiz = (specialQuizId, username, callback) => {
    socket.emit(
      "new special quiz",
      { specialQuizId: specialQuizId, username: username },
      (response) => {
        callback(response);
      }
    );
  };

  // Emit for specialQuizAnswer
  const emitSpecialQuizAnswer = ({ answer, specialQuizId }, callback) => {
    socket.emit(
      "special quiz answer",
      { answer: answer, specialQuizId: specialQuizId },
      (response) => {
        callback(response);
      }
    );
  };

  // Listener for specialQuizStart
  const onSpecialQuizStarted = (callback) => {
    socket.on("special quiz started", (data) => {
      callback(data);
    });
  };

  // Listener for specialQuizQuestion
  const onSpecialQuizQuestion = (callback) => {
    socket.on("send special question", (data) => {
      callback(data);
    });
  };

  // Listener for specialQuizCorrectAnswer
  const onSpecialQuizCorrectAnswer = (callback) => {
    socket.on("send special answer", (data) => {
      callback(data);
    });
  };

  // Listener for specialQuizResults
  const onSpecialQuizResult = (callback) => {
    socket.on("send special result", (data) => {
      callback(data);
    });
  };

  // Cleanup functions
  const offSpecialQuizStarted = () => {
    socket.off("special quiz started");
  };

  const offSpecialQuizQuestion = () => {
    socket.off("send special question");
  };

  const offSpecialQuizCorrectAnswer = () => {
    socket.off("send special answer");
  };

  const offSpecialQuizResult = () => {
    socket.off("send special results");
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectSocket,
        disconnectSocket,
        emitInit,
        emitLogout,
        emitNewSpecialQuiz,
        emitSpecialQuizAnswer,
        onSpecialQuizStarted,
        onSpecialQuizQuestion,
        onSpecialQuizCorrectAnswer,
        onSpecialQuizResult,
        offSpecialQuizStarted,
        offSpecialQuizQuestion,
        offSpecialQuizCorrectAnswer,
        offSpecialQuizResult,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
