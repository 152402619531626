import React, { useEffect } from "react";
import styles from "./MainPageTemplate.module.css";

import Carousel from "../../organisms/Carousel/Carousel";
import GamePanels from "../../molecules/GamePanels/GamePanels";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CashTournamentsSectionV2 from "../../organisms/CashTournamentsSectionV2/CashTournamentsSectionV2";
import SpecialQuizSection from "../../organisms/SpecialQuizSection/SpecialQuizSection";
const MainPageTemplate = () => {
  const { t } = useTranslation();

  const dateTo = new Date("06/13/24");
  const dateTo2 = new Date("05/01/24");
  const { isMobile, setPlayingModeStatus, loggedIn } = useAuth();
  const navigate = useNavigate();

  // this added because when the user hits the back button and goes to home,the nav side icons need to be visible
  useEffect(() => {
    setPlayingModeStatus(false);
  }, [setPlayingModeStatus]);

  return (
    <div className={styles.mainPageTemplate}>
      <Carousel />
      <div
        className={
          loggedIn
            ? styles.gameSelectionSectionLoggedIn
            : styles.gameSelectionSectionLoggedOut
        }
      >
        <div className={styles.title}>
          <span className={styles.text}>
            <Typography
              variant="headingL"
              fontWeight="font700"
              color="colorBlack"
            >
              {t("mainNav.homeTab.popularQuizGames.title")}
            </Typography>
            {/* <Icon name={"coin"} folder="other_icons" file="png" size={48} /> */}
          </span>
          {/* <MainPageArrows
            onClickLeft={() => console.log('left arrow')}
            onClickRight={() => console.log('right arrow')}
          /> */}
        </div>
        <GamePanels group="popular" />
      </div>
      <div className={styles.line}></div>
      {loggedIn && (
        <>
          <div
            className={
              loggedIn
                ? styles.gameSelectionSectionLoggedIn
                : styles.gameSelectionSectionLoggedOut
            }
          >
            <div className={styles.title}>
              <span className={styles.text}>
                <Typography
                  variant="headingL"
                  fontWeight="font700"
                  color="colorBlack"
                >
                  {t("Prize tournaments")}
                </Typography>
              </span>
              {/* <MainPageArrows
            onClickLeft={() => console.log('left arrow')}
            onClickRight={() => console.log('right arrow')}
          /> */}
              {/* <MainPageArrows
            onClickLeft={() => console.log('left arrow')}
            onClickRight={() => console.log('right arrow')}
          /> */}
            </div>
            {/* <TournamentPanel /> */}

            <CashTournamentsSectionV2 />
          </div>
        </>
      )}
      {loggedIn || (
        <>
          <div
            className={
              loggedIn
                ? styles.gameSelectionSectionLoggedIn
                : styles.gameSelectionSectionLoggedOut
            }
          >
            <div className={styles.title}>
              <span className={styles.text}>
                <Typography
                  variant="headingL"
                  fontWeight="font700"
                  color="colorBlack"
                >
                  {t("Prize tournaments")}
                </Typography>
              </span>
            </div>
            <CashTournamentsSectionV2 />
          </div>
        </>
      )}
      <div className={styles.line}></div>

      <div
        className={
          loggedIn
            ? styles.gameSelectionSectionLoggedIn
            : styles.gameSelectionSectionLoggedOut
        }
      >
        <div className={styles.title}>
          <span className={styles.text}>
            <Typography
              variant="headingL"
              fontWeight="font700"
              color="colorBlack"
            >
              {t("mainNav.homeTab.specialQuizzes.title")}
            </Typography>
          </span>
        </div>
        {/* <GamePanels group="special" /> */}
        <SpecialQuizSection />
      </div>
      <div className={styles.line}></div>

      {/* <div className={styles.spinner}>
        <Tooltip under text={'spin the wheel!'}>
          <Button onClick={() => navigate('/spin')}>
            <Icon name={'spinner'} folder="other_icons" size={isMobile ? 64 : 110}></Icon>
          </Button>
        </Tooltip>
      </div> */}
    </div>
  );
};

export default MainPageTemplate;
