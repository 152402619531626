import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button/Button.js";
import Typography from "../../atoms/Typography/Typography.js";
import styles from "./StoreTransactionButton.module.css";
import { initializePayment } from "../../../apiHelpers";
import { useAuth } from "../../../AthContext";
import { useLoginExpired } from "../../../customHooks";

const StoreTransactionButton = ({ additionalClass, text, androidProductId }) => {
  const [priceToPay, setPriceToPay] = useState('')
  const navigate = useNavigate();
  const { isMobile, setUserWantsToBuyItem } = useAuth();
  const loginExpired = useLoginExpired();

  const handleClick = async() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const cart = androidProductId;
    // console.log('cart id:',cart);
    // console.log('user id:',userData.internalId);
    // console.log('user name:',userData.username);
    try {
      const additionalParams = {
        internalId: userData.internalId,
        username: userData.username,
      };
  
      const paymentResponse = await initializePayment(cart, additionalParams);
      const responseCashierToken = paymentResponse.cashierToken.result.cashier_token;
      // console.log('this is response', responseCashierToken);
      setUserWantsToBuyItem(true);
      navigate('/card', { state: { cashierToken: responseCashierToken } });
    } catch (error) {
      console.error('Error retrieving payment information:', error);
    }
  };
  
    //. ================  old functionality (stripe) ==================== .//
    // try {
    //   // Call the payment function to obtain the clientSecret
    //   const paymentResponse = await initializePayment(androidProductId);
    //   const { clientSecret, price } = paymentResponse;

    //   // Log the clientSecret to see the output
    //   console.log(paymentResponse);
    //   setPriceToPay(paymentResponse.price)
    //   setUserWantsToBuyItem(true);
    //   // If the clientSecret is successfully retrieved, redirect to /card
    //   navigate('/card', { state: { clientSecret, androidProductId, priceToPay:price } });
    // } catch (error) {
    //   console.error('Error retrieving payment information:', error);
    //   if (error.response?.status === 401) {
    //     loginExpired()
    //   }
    // }
    //. ====== end ======  old functionality (stripe) ==================== .//


  return (
    <Button
      additionalClass={styles.storeTransactionButton + " " + additionalClass}
      onClick={handleClick}
    >
      <Typography
        color="colorWhite"
        fontWeight="font900"
        variant="heading3"
        additionalClass={styles.storeTransactionButtonText}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default StoreTransactionButton;
