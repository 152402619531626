import React, { useEffect, useState } from "react";
import styles from "./LeaderboardsTemplate.module.css";
import Typography from "../../atoms/Typography/Typography";
import GameModeSlider from "../../molecules/GameModeSlider/GameModeSlider";
import LeaderboardContainer from "../../organisms/LeaderboardContainer/LeaderboardContainer";
import { useAuth } from "../../../AthContext";
import {
  getAllTimeData,
  getFriendsLeaderboardData,
  getSpecialQuizLeaderboardData,
} from "../../../apiHelpers";
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";
import { useLoginExpired } from "../../../customHooks";

const LeaderboardsTemplate = () => {
  const [selectedGameMode, setSelectedGameMode] = useState("friends");
  const [loading, setLoading] = useState(false);
  const loginExpired = useLoginExpired();
  const { isMobile, translateText, updateLeaderboardData, leaderboardData } =
    useAuth();
  const [filteredFriendsData, setFilteredFriendsData] = useState([]);
  const [filteredSpecialQuizzesData, setFilteredSpecialQuizzesData] = useState(
    []
  );

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const leads = await getAllTimeData(0);
        updateLeaderboardData(leads);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        if (error.response?.status === 401) {
          loginExpired();
        }
      } finally {
        setLoading(false);
      }
    };

    // if (selectedGameMode === "global") {
    fetchLeaderboardData();
    // }
  }, []);

  const getFriendsLeaderboard = async (index) => {
    const data = await getFriendsLeaderboardData(index);
    setFilteredFriendsData(data);
  };

  useEffect(() => {
    getFriendsLeaderboard(0);
  }, []);

  useEffect(() => {
    const fetchLeaderboardSpecialQuizData = async () => {
      setLoading(true); // Show loading spinner during the fetch
      let specialQuizType;
      try {
        // if (selectedGameMode === "specialQuizzesTotal") {
        //   specialQuizType = "alltime";
        // } else {
        //   specialQuizType = "weekly";
        // }
        const leads = await getSpecialQuizLeaderboardData(0, "weekly");
        const finalLead = [leads.userData, ...leads.leaderboardList];
        setFilteredSpecialQuizzesData(finalLead);
      } catch (error) {
        console.error("Error fetching leaderboard special quiz data:", error);
        if (error.response?.status === 401) {
          loginExpired();
        }
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };

    // if (
    //   selectedGameMode === "specialQuizzesTotal" ||
    //   selectedGameMode === "specialQuizzesWeekly"
    // ) {
    fetchLeaderboardSpecialQuizData();
    // }
  }, []);

  const handleGameModeChange = (index) => {
    // Map the selected index to the corresponding game mode
    const modes = [
      "friends",
      "global",
      "specialQuizzesWeekly",
      "specialQuizzesTotal",
    ];
    setSelectedGameMode(modes[index]);
  };

  return (
    <div className={styles.leaderboardsTemplate}>
      <Typography
        variant={isMobile ? "heading2" : "headingL"}
        fontWeight="font700"
        color="colorBlack"
        additionalClass={styles.leaderboardTitle}
      >
        {translateText("mainNav.leaderboardTab.title")}
      </Typography>
      <div className={styles.slideContainer}>
        <GameModeSlider
          mode
          className={styles.slider}
          onChange={handleGameModeChange}
        />
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <LeaderboardContainer
          gameMode={selectedGameMode}
          filteredFriendsData={filteredFriendsData}
          setFilteredFriendsData={setFilteredFriendsData}
          leaderboardData={leaderboardData}
          filteredSpecialQuizzesData={filteredSpecialQuizzesData}
        />
      )}
    </div>
  );
};

export default LeaderboardsTemplate;
