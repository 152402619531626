import React, { useState } from "react";
import styles from "./WalletContainer.module.css";
import WalletNotification from "../../molecules/WalletNotification/WalletNotification";
import WalletButton from "../../molecules/WalletButton/WalletButton";
import { useTranslation } from "react-i18next";
import ModalWith1Button from "../../molecules/ModalWith1Button/ModalWith1Button";

const WalletContainer = ({ walletData, coins }) => {
  const [showModal, setShowModal] = useState(false);
  const [walletTransaction, setWalletTransaction] = useState("");
  const { t } = useTranslation();

  const availableCashRounded = walletData?.available.toFixed(2);
  const MINIMUM_WITHDRAW_AMOUNT = 25;
  const MINIMUM_REDEEM_AMOUNT = 600;

  const walletInfo = [
    {
      id: 1,
      amount: availableCashRounded,
      currency: "€",
      description: "Withdraw",
      transaction: "withdraw",
    },
    {
      id: 2,
      amount: coins,
      currency: "Coins",
      description: "Redeem",
      transaction: "redeem",
    },
  ];

  const handleClick = (currency, amount) => {
    setShowModal(true);
    if (currency === "€" && amount >= MINIMUM_WITHDRAW_AMOUNT)
      setWalletTransaction("popUpWithdrawAvailable");
    else if (currency === "€" && amount < MINIMUM_WITHDRAW_AMOUNT)
      setWalletTransaction("popUpWithdrawNotAvailable");
    else if (currency === "Coins" && amount >= MINIMUM_REDEEM_AMOUNT)
      setWalletTransaction("popUpRedeemAvailable");
    else if (currency === "Coins" && amount < MINIMUM_REDEEM_AMOUNT)
      setWalletTransaction("popUpRedeemNotAvailable");
  };

  return (
    <>
      {showModal && (
        <ModalWith1Button
          mainTitle={t(`mainNav.walletTab.${walletTransaction}.title`)}
          closeButtonTitle={t("generic.ok")}
          onClick={() => setShowModal(false)}
          secondaryTitle={t(`mainNav.walletTab.${walletTransaction}.body`)}
        />
      )}
      <div className={styles.walletContainer}>
        <div className={styles.notificationsContainer}>
          {walletInfo.map(
            ({ id, amount, currency, description, transaction }) => (
              <div key={id} className={styles.walletBalance}>
                <WalletNotification
                  headText={`${amount} ${currency}`}
                  description={t(`mainNav.walletTab.availableTo${description}`)}
                />
                <div
                  className={`${styles.walletButton} ${
                    (amount < MINIMUM_WITHDRAW_AMOUNT && currency === "€") ||
                    (amount < MINIMUM_REDEEM_AMOUNT && currency === "Coins")
                      ? styles.walletButtonDisabled
                      : ""
                  }`}
                >
                  <WalletButton
                    leftIcon={transaction}
                    text={t(`mainNav.walletTab.${transaction}`)}
                    onClick={() => handleClick(currency, amount)}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default WalletContainer;
