import React, { useEffect, useState } from "react";
import StoreAvatarGroup from "../../organisms/StoreAvatarGroup/StoreAvatarGroup";
import StoreBoosterStore from "../../organisms/StoreBoosterStore/StoreBoosterStore";
import StoreBoosterPackageGroup from "../../organisms/StoreBoosterPackageGroup/StoreBoosterPackageGroup";
import StoreTicketSection from "../../organisms/StoreTicketSection/StoreTicketSection";
import StoreWheelSection from "../../organisms/StoreWheelSection/StoreWheelSection";
import StoreCoinsGroup from "../../organisms/StoreCoinsGroup/StoreCoinsGroup";
import { useAuth } from "../../../AthContext";
import Typography from "../../atoms/Typography/Typography";
import Button from "../../atoms/Button/Button";
import { getStoreData } from "../../../apiHelpers";
import { useLoginExpired } from "../../../customHooks";
import styles from "./StoreTemplate.module.css";
import ComingSoon from "../../molecules/ComingSoon/ComingSoon";
import { useTranslation } from "react-i18next";

const StoreTemplate = () => {
  const { isMobile, language } = useAuth();
  const [storeDataToRender, setStoreDataToRender] = useState([]);
  const loginExpired = useLoginExpired();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await getStoreData(language);
        setStoreDataToRender(apiResponse?.storeData);
      } catch (error) {
        console.error("Error fetching store data:", error);
        if (error.response?.status === 401) {
          loginExpired();
        }
      }
    };

    fetchData();
  }, []);

  if (storeDataToRender.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.storeContainerEntire}>
        <Typography
          color="colorBlack"
          variant="heading1"
          fontWeight="font900"
          additionalClass={styles.storeTitle}
        >
          Store
        </Typography>
        {/* <ComingSoon> */}
        {isMobile ? (
          // <ComingSoon variant="gold">
            <div className={styles.storeContainerMobile}>
              <StoreAvatarGroup storeDataToRender={storeDataToRender} />
              {/* <StoreBoosterStore storeDataToRender={storeDataToRender} /> */}
              {/* <StoreBoosterPackageGroup storeDataToRender={storeDataToRender} /> */}
              <StoreTicketSection storeDataToRender={storeDataToRender} />
              {/* <StoreWheelSection storeDataToRender={storeDataToRender} /> */}
              {/* <StoreCoinsGroup storeDataToRender={storeDataToRender} /> */}
            </div>
          // </ComingSoon>
        ) : (
          // <ComingSoon variant="gold">
            <div className={styles.storeContainerDesktopEntire}>
              <div className={styles.storeContainerDesktopLeft}>
                <StoreAvatarGroup storeDataToRender={storeDataToRender} />
                <StoreTicketSection storeDataToRender={storeDataToRender} />
                {/* <StoreWheelSection storeDataToRender={storeDataToRender} /> */}
              </div>
              <div className={styles.storeContainerDesktopRight}>
                {/* <StoreBoosterStore storeDataToRender={storeDataToRender} /> */}
                {/* <StoreBoosterPackageGroup storeDataToRender={storeDataToRender} /> */}
                {/* <StoreCoinsGroup storeDataToRender={storeDataToRender} /> */}
              </div>
            </div>
          // </ComingSoon>
        )}
        {/* <Typography color="black" variant="body2" additionalClass={styles.guaranteeText1}>
            {t('generic.moneyGuarantee1')}
          </Typography>
          <Typography color="black" variant="body2" additionalClass={styles.guaranteeText2}>
            {t('generic.moneyGuarantee2')}
          </Typography> */}
        <Button additionalClass={styles.resetButton}>
          <Typography color="colorWhite" variant="body1">
            Reset purchases
          </Typography>
        </Button>
        {/* </ComingSoon> */}
      </div>
    </>
  );
};

export default StoreTemplate;
