import React, { useEffect, useState } from "react";
import { getSpecialQuizInfo, getSpecialQuizIds } from "../../../apiHelpers";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";
import { useLoginExpired } from "../../../customHooks";
import SpecialQuizCard from "../../molecules/SpecialQuizCard/SpecialQuizCard";
import styles from "./SpecialQuizSection.module.css";
import LoadingSpinnerLarge from "../../molecules/LoadingSpinnerLarge/LoadingSpinnerLarge";

const SpecialQuizSection = () => {
  const { t } = useTranslation();
  const [availableSpecialQuizIDs, setAvailableSpecialQuizIDs] = useState([]);
  const [availableSpecialQuizInfo, setAvailableSpecialQuizInfo] = useState([]);
  const { loggedIn } = useAuth();
  const loginExpired = useLoginExpired();
  const { playerInfo } = useAuth();

  let userInternalId;

  if (loggedIn) {
    userInternalId = playerInfo?.internalId;
  }

  //Fetch the IDs of all available Cash Tournaments

  useEffect(() => {
    const fetchSpecialQuizIds = async () => {
      try {
        const tournamentIDs = await getSpecialQuizIds();
        setAvailableSpecialQuizIDs(tournamentIDs);
      } catch (error) {
        console.error("Error fetching store data:", error);
        if (error.response?.status === 401) {
          loginExpired();
        }
      }
    };
    fetchSpecialQuizIds();
  }, [loggedIn, userInternalId]);

  //Fetch the Data of all available Cash Tournaments based on the available tournament IDs

  useEffect(() => {
    const fetchSpecialQuizInfo = async () => {
      try {
        const specialQuizInfo = await Promise.all(
          availableSpecialQuizIDs.map((specialQuizId) =>
            getSpecialQuizInfo(specialQuizId, userInternalId)
          )
        );
        setAvailableSpecialQuizInfo(specialQuizInfo.map((data) => data));
      } catch (error) {
        console.error("Error in getting tournament info:", error);
        loginExpired();
      }
    };
    fetchSpecialQuizInfo();
  }, [loggedIn, availableSpecialQuizIDs]);

  return (
    <div className={styles.tournamentPanelsContainer}>
      {availableSpecialQuizInfo.length === 0 ? (
        <div className={styles.loadingSpinner}>
        <LoadingSpinnerLarge />
        </div>
      ) : (
        availableSpecialQuizInfo?.map((specialQuiz) => {
          return (
            <SpecialQuizCard
              key={specialQuiz?._id}
              id={specialQuiz?._id}
              name={specialQuiz?.name}
              imageName1={specialQuiz?.backgroundImage}
              textQuestionsPrize={specialQuiz?.description}
              backgroundColor={specialQuiz?.backgroundColor}
              specialQuizId={specialQuiz?._id}
              endingAt={specialQuiz?.endingAt}
              isSpecialQuizCompleted={specialQuiz?.completionStatus}
            />
          );
        })
      )}
    </div>
  );
};

export default SpecialQuizSection;
