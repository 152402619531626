import React from 'react';
import PropTypes from 'prop-types';
import styles from './LeaderboardEntry.module.css'; // Import your styles
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import { useAuth } from '../../../AthContext';

const LeaderboardEntry = ({
  index,
  position,
  avatarId,
  username,
  games = '',
  wins = '',
  losses = '',
  medals = [],
  points,
}) => {
  const { isMobile } = useAuth();
  const truncateUsername = username.length > 30 ? `${username.substring(0,30)}...` : username

  return (
    <div className={styles.leaderboardEntry}>
      <span className={styles.skewedBorder}></span>
      <div className={styles.entryContent}>
        <div className={styles.position}>
          <Typography
            variant={isMobile ? 'body1' : 'heading2'}
            fontWeight="font900"
            additionalClass={
              position === 1
                ? styles.gold
                : position > 3 && position < 100
                ? styles.grey
                : position === 2
                ? styles.silver
                : position > 100
                ? styles.self
                : styles.bronze
            }
          >
            {position > 0 || index + 1 ? (position || index + 1) : "-" }
          </Typography>
        </div>
        <Icon
          folder="avatars"
          name={`avatar-${avatarId}`}
          size={isMobile ? 32 : 48}
        />
        <div className={styles.userData}>
          <div className={styles.username}>
            <Typography
              variant={isMobile ? 'caption' : 'body1'}
              fontWeight={'font700'}
              color="colorBlack"
            >
              {truncateUsername}
            </Typography>
          </div>
          <div className={styles.stat}>
            <Typography
              variant={isMobile ? 'caption' : 'body1'}
              fontWeight={'font500'}
              color="colorBlack"
            >
              {games}
            </Typography>
          </div>
          <div className={styles.stat}>
            <Typography
              variant={isMobile ? 'caption' : 'body1'}
              fontWeight={'font500'}
              color="colorBlack"
            >
              {wins}
            </Typography>
          </div>
          <div className={styles.stat}>
            <Typography
              variant={isMobile ? 'caption' : 'body1'}
              fontWeight={'font500'}
              color="colorBlack"
            >
              {losses}
            </Typography>
          </div>

          {/* {!isMobile && (
            <div
              className={`${styles.stat} ${styles.medals}`}
              style={{ flex: 1 }}
            >
              {medals &&
                medals.map((medal, index) => (
                  <span key={index} className={styles.medal}>
                    <Icon folder="medals" name={medal} />
                  </span>
                ))}
            </div>
          )} */}
        </div>
        <div className={styles.points}>
          <Typography
            variant={isMobile ? 'caption' : 'body1'}
            fontWeight={'font700'}
            color="colorBlack"
          >
            {points}
          </Typography>
        </div>
      </div>
    </div>
  );
};

LeaderboardEntry.propTypes = {
  index: PropTypes.number.isRequired,
  avatarId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  games: PropTypes.number,
  wins: PropTypes.number,
  losses: PropTypes.number,
  medals: PropTypes.array,
  points: PropTypes.string.isRequired,
};

export default LeaderboardEntry;
