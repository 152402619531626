import React from "react";
import styles from "./CongratulationsBoxSpecialQuiz.module.css";
import Typography from "../../atoms/Typography/Typography";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CongratulationsStatistics from "../../molecules/CongratulationsStatistics/CongratulationsStatistics";

const CongratulationsBoxSpecialQuiz = ({
  quizEvaluationResults,
  questionsLength,
  rightAnswers,
  userID,
  pollfishApiToken,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const quizEvaluationResultsTexts = {
    perfect: {
      header: t("congratulationsMessage.perfect.primary"),
      caption: t("congratulationsMessage.perfect.secondary"),
    },
    great: {
      header: t("congratulationsMessage.great.primary"),
      caption: t("congratulationsMessage.great.secondary"),
    },
    well: {
      header: t("congratulationsMessage.well.primary"),
      caption: t("congratulationsMessage.well.secondary"),
    },
    fail: {
      header: t("congratulationsMessage.fail.primary"),
      caption: t("congratulationsMessage.fail.secondary"),
    },
  };

  const { header, caption } =
    quizEvaluationResultsTexts[quizEvaluationResults] ||
    quizEvaluationResultsTexts.perfect; // Default to "perfect" scenario

  const handleButtonClick = () => {
    navigate("/", { replace: true }); // Navigate to home page and replace the current entry in the history stack
  };

  const handleButtonProdege = () => {
    window.open(
      `https://web.bitlabs.ai/?uid=${userID}&token=${pollfishApiToken}`,
      "_blank"
    );
  };

  return (
    <div className={`${styles["box-container"]}`}>
      <div className={`${styles["text-container"]}`}>
        <Typography
          variant="heading2"
          fontWeight="font700"
          additionalClass={`${styles["header-text"]}`}
        >
          {header}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="font700"
          color="colorBlack"
          additionalClass={`${styles["caption-text"]}`}
        >
          {caption}
        </Typography>
      </div>
      <div className={styles.scoresContainer}>
        <div className={styles.statisticsContainer}>
          <CongratulationsStatistics
            number={`${rightAnswers}/${questionsLength}`}
            text={t("battleRoyalResultScreen.correctAnswers")}
          />
        </div>
      </div>
      <PlayButton
        primaryClass="skewed-play-button"
        additionalClass="play-now"
        primaryText="OK"
        onClick={handleButtonClick}
      />
      <PlayButton
        additionalClass="play-prize-shine"
        primaryText="Quick Survey for a Reward!"
        shineClass={"shiny-effect"}
        onClick={handleButtonProdege}
      />
    </div>
  );
};

export default CongratulationsBoxSpecialQuiz;
