import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./LeaderboardContainer.module.css"; // Import your styles

import LeaderboardEntry from "../../molecules/LeaderboardEntry/LeaderboardEntry";

import { useAuth } from "../../../AthContext";
import Typography from "../../atoms/Typography/Typography";
import LeaderboardHeadings from "../../molecules/LeaderboardHeadings/LeaderboardHeadings";
import { getFriendsLeaderboardData } from "../../../apiHelpers";
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";

const LeaderboardContainer = ({ gameMode, filteredFriendsData, filteredSpecialQuizzesData }) => {
  const {
    isMobile,
    translateText,
    leaderboardData,
  } = useAuth();
  const leaderboardHeadings = [
    translateText("generic.name"),
    translateText("mainNav.profileTab.stats.statistics.games"),
    translateText("mainNav.profileTab.stats.statistics.wins"),
    translateText("mainNav.profileTab.stats.statistics.loses"),
    // !isMobile && translateText("mainNav.profileTab.stats.medals"),
  ];

  // const [filteredFriendsData, setFilteredFriendsData] = useState([]);

  // const getFriendsLeaderboard = async (index) => {
  //   const data = await getFriendsLeaderboardData(index);
  //   setFilteredFriendsData(data);
  // };
  // useEffect(() => {
  //   getFriendsLeaderboard(0);
  // }, [gameMode, leaderboardData]);

  // if (!leaderboardData) {
  //   return null; // or loading indicator or any other UI you prefer while data is loading
  // }

  const currentData =
    gameMode === "friends"
      ? filteredFriendsData
      : gameMode === "specialQuizzesWeekly"
      ? filteredSpecialQuizzesData
      : leaderboardData;

  // Early loading or no data indication
  if (!currentData || currentData.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: "90%" }}>
      <div>
        <div className={styles.lines}>
          {gameMode === "friends" && (
            <>
              <LeaderboardHeadings
                leaderboardHeadings={leaderboardHeadings}
                isMobile={isMobile}
                gameMode={gameMode}
              />
              {filteredFriendsData.map((entry, index) => (
                <LeaderboardEntry
                  key={index}
                  index={index}
                  position={entry?.position}
                  avatarId={entry?.avatarId}
                  username={entry?.username}
                  games={entry?.games}
                  wins={entry?.wins}
                  losses={entry?.losses}
                  medals={entry?.medals}
                  points={entry?.score}
                />
              ))}
            </>
          )}

          {gameMode === "global" && (
            <>
              <LeaderboardHeadings
                leaderboardHeadings={leaderboardHeadings}
                isMobile={isMobile}
                gameMode={gameMode}
              />
              {leaderboardData.map((entry, index) => (
                <LeaderboardEntry
                  key={index}
                  position={entry?.position}
                  avatarId={entry?.avatarId}
                  username={entry?.username}
                  games={entry?.games}
                  wins={entry?.wins}
                  losses={entry?.losses}
                  medals={entry?.medals}
                  points={entry?.score}
                />
              ))}
            </>
          )}

          {gameMode === "specialQuizzesWeekly" && (
            <>
              <LeaderboardHeadings
                leaderboardHeadings={leaderboardHeadings}
                isMobile={isMobile}
                gameMode={gameMode}
              />
              {filteredSpecialQuizzesData.map((entry, index) => (
                <LeaderboardEntry
                  key={index}
                  position={entry?.position}
                  avatarId={entry?.avatarId}
                  username={entry?.username}
                  // games={entry?.games}
                  // wins={entry?.wins}
                  // losses={entry?.losses}
                  // medals={entry?.medals}
                  points={entry?.score}
                />
              ))}
            </>
          )}

          {/* {gameMode === "specialQuizzesTotal" && ( // <= Future addition
            <>
              <LeaderboardHeadings
                leaderboardHeadings={leaderboardHeadings}
                isMobile={isMobile}
                gameMode={gameMode}
              />
              {leaderboardData.map((entry, index) => (
                <LeaderboardEntry
                  key={index}
                  position={entry?.position}
                  avatarId={entry?.avatarId}
                  username={entry?.username}
                  // games={entry?.games}
                  // wins={entry?.wins}
                  // losses={entry?.losses}
                  // medals={entry?.medals}
                  points={entry?.score}
                />
              ))}
            </>
          )} */}

          {gameMode !== "friends" &&
            gameMode !== "global" &&
            gameMode !== "specialQuizzesWeekly" &&
            gameMode !== "specialQuizzesTotal" && ( // <= Future addition
              <Typography
                fontWeight={"font900"}
                variant={"heading3"}
                color="colorBlack"
                additionalClass={styles.comingSoon}
              >
                Coming Soon
              </Typography>
            )}
        </div>
      </div>
    </div>
  );
};

LeaderboardContainer.propTypes = {
  gameMode: PropTypes.string.isRequired,
  filteredFriendsData: PropTypes.array,
  leaderboardData: PropTypes.array,
  filteredSpecialQuizzesData: PropTypes.array,
};

export default LeaderboardContainer;
