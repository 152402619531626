import React, { useState, useEffect } from "react";
import {
  useLocation,
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import ReactGA from "react-ga4";
import HeaderMenu from "./components/organisms/HeaderMenu/HeaderMenu";
import MainPageTemplate from "./components/templates/MainPageTemplate/MainPageTemplate";
import QuizSelectionTemplate from "./components/templates/QuizSelectionTemplate/QuizSelectionTemplate";
import MenuPanel from "./components/organisms/MenuPanel/MenuPanel";
import LeaderboardsTemplate from "./components/templates/LeaderboardsTemplate/LeaderboardsTemplate";
import BackgroundGeneric from "./components/molecules/BackgroundGeneric/BackgroundGeneric";
import WelcomePage from "./components/templates/WelcomePage/WelcomePage";
import StoreTemplateV2 from "./components/templates/StoreTemplateV2/StoreTemplateV2";
// TO BE REMOVED
import StoreTemplate from "./components/templates/StoreTemplate/StoreTemplate";
import TemplateProfile from "./components/templates/TemplateProfile/TemplateProfile";
import SignupLoginTemplate from "./components/templates/SignupLoginTemplate/SignupLoginTemplate";
import SinglePlayerMode from "./components/templates/SinglePlayerMode/SinglePlayerMode";
import WalletTemplate from "./components/templates/WalletTemplate/WalletTemplate";
import BoostersTemplate from "./components/templates/BoostersTemplate/BoostersTemplate";
import FriendsTemplate from "./components/templates/FriendsTemplate/FriendsTemplate";
import WaitingScreen from "./components/organisms/WaitingScreen/WaitingScreen";
import CongratulationsTemplate from "./components/templates/CongratulationsTemplate/CongratulationsTemplate";
import OtherUsersProfileTemplate from "./components/templates/OtherUserProfileTemplate/OtherUserProfileTemplate";
import TournamentTemplate from "./components/templates/TournamentTemplate/TournamentTemplate";
import { AuthProvider, useAuth } from "./AthContext";
import MobileMenu from "./components/organisms/MobileMenu/MobileMenu";
import SpinWheelTemplate from "./components/templates/SpinWheelTemplate/SpinWheelTemplate";
import { setAuthToken } from "./services/setAuthToken";
import { getUserData, getUserInfo } from "./apiHelpers";
import { GameProvider } from "./GameContext";
import PvPmode from "./components/templates/PvPmode/PvPmode";
import "./services/i18n";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
import { StripeModal } from "./components/templates/StripeModal/StripeModal";
import { BridgePayModal } from "./components/templates/BridgerPayModal/BridgerPayModal";
import IngameHeader from "./components/organisms/IngameHeader/IngameHeader";
import { SocketProvider, useSocket } from "./SocketContext";
import TournamentAdminPanel from "./components/organisms/TournamentAdminPanel/TournamentAdminPanel";
import TournamentTesting from "./components/templates/TournamentTesting/TournamentTesting";
import SpecialQuiz from "./components/templates/SpecialQuiz/SpecialQuiz";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WP7MX5D7",
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize("G-HW9CDD67J7");

const googleKey = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const App = () => {
  return (
    <Router>
      <GoogleOAuthProvider clientId={googleKey}>
        <SocketProvider>
          <GameProvider>
            <AuthProvider>
              <BackgroundGeneric />

              <AuthRoutes />
            </AuthProvider>
          </GameProvider>
        </SocketProvider>
      </GoogleOAuthProvider>
    </Router>
  );
};

const AuthRoutes = () => {
  const {
    loggedIn,
    isMobile,
    setPlayingModeStatus,
    isUserAboutToPlay,
    playerInfo,
    updatePlayerInfo,
    language,
    userWantsToBuyItem,
  } = useAuth();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const token = localStorage?.getItem("token");
  const id = JSON.parse(localStorage?.getItem("id"));
  const [activeIndex, setActiveIndex] = useState(1);
  const { connectSocket, disconnectSocket, emitInit, emitLogout } = useSocket();

  // This useEffect runs on component mount
  useEffect(() => {
    // Code logic here for when the component mounts
    //console.log("Component mounted");

    // Cleanup function for when the component unmounts or page refreshes
    const cleanup = () => {
      // Code logic here for when the component unmounts or page refreshes
      //console.log("Component unmounted or page refreshed");

      // Disconnect the socket here
      emitLogout();
      disconnectSocket();
    };
    // Add event listener for beforeunload event
    window.addEventListener("beforeunload", cleanup);

    // Return cleanup function to be executed when component unmounts
    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  useEffect(() => {
    // Send page view event to Google Analytics 4
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
      page_title: document.title,
    });
  }, [location]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data if token is available
        connectSocket();
        if (token) {
          await emitInit();
          // setAuthToken(token)
          await getUserData();
        }

        // Fetch player info if id is available
        if (id) {
          const userInfo = await getUserInfo(id);
          // Update player info
          updatePlayerInfo(userInfo);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, token]);

  useEffect(() => {
    const path = location.pathname;
    if (
      path === "/in-game" ||
      path === "/congratulations" ||
      path === "/versus" ||
      path === "/tournament" ||
      path === "/specialQuiz"
    ) {
      setPlayingModeStatus(true);
    } else {
      setPlayingModeStatus(false); // Set to false when not in '/in-game'
    }
  }, [location]); // Depend on location to re-run this effect

  return (
    <>
      {isUserAboutToPlay && <IngameHeader />}
      {!isUserAboutToPlay && (
        <HeaderMenu activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      )}
      {loggedIn &&
        !isUserAboutToPlay &&
        (isMobile ? <MobileMenu /> : <MenuPanel />)}
      <Routes>
        <Route path="/" element={<MainPageTemplate />} />
        <Route
          path="/forms"
          element={
            loggedIn ? (
              <Navigate replace to="/" />
            ) : (
              <SignupLoginTemplate playerData={playerInfo} />
            )
          }
        />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route
          path="/quiz"
          element={<QuizSelectionTemplate isMobile={isMobile} />}
        />
        <Route
          path="/leaderboards"
          element={
            loggedIn ? (
              <LeaderboardsTemplate />
            ) : (
              <Navigate replace to="/forms" />
            )
          }
        />
        <Route
          path="/store"
          element={
            loggedIn ? <StoreTemplateV2 /> : <Navigate replace to="/forms" />
          }
        />
        {/* TO BE REMOVED */}
        <Route
          path="/store/store"
          element={
            loggedIn ? <StoreTemplate /> : <Navigate replace to="/forms" />
          }
        />
        <Route
          path="/wallet"
          element={
            loggedIn ? (
              <WalletTemplate walletData={playerInfo?.walletData} />
            ) : (
              <Navigate replace to="/forms" />
            )
          }
        />
        <Route
          path="/friends"
          element={
            loggedIn ? <FriendsTemplate /> : <Navigate replace to="/forms" />
          }
        />
        <Route
          path="/profile"
          element={
            loggedIn ? <TemplateProfile /> : <Navigate replace to="/forms" />
          }
        />
        <Route path="/in-game" element={<SinglePlayerMode />} />
        <Route path="/versus" element={<PvPmode />} />
        <Route path="/boosters" element={<BoostersTemplate />} />
        <Route path="/loading" element={<WaitingScreen />} />
        <Route path="/congratulations" element={<CongratulationsTemplate />} />
        <Route
          path="/user-profile"
          element={
            loggedIn ? (
              <OtherUsersProfileTemplate />
            ) : (
              <Navigate replace to="/forms" />
            )
          }
        />
        <Route path="/spin" element={<SpinWheelTemplate />} />
        <Route
          path="/card"
          element={
            userWantsToBuyItem ? (
              <BridgePayModal />
            ) : (
              <Navigate replace to="/store" />
            )
          }
        />
        <Route path="/tournament" element={<TournamentTemplate />} />
        {/* <Route
          path="/tournamentAdmin"
          element={
            loggedIn ? (
              <TournamentAdminPanel />
            ) : (
              <Navigate replace to="/forms" />
            )
          }
        /> */}
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="specialQuiz" element={<SpecialQuiz />} />
      </Routes>
    </>
  );
};

export default App;
