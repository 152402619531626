import React, { useState } from "react";
import ReactDOM from "react-dom"; // Import ReactDOM
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import { useNavigate } from "react-router-dom";
import { useLoginExpired } from "../../../customHooks";
import { useTranslation } from "react-i18next";
import { subscribeTournament } from "../../../apiHelpers";
import { useAuth } from "../../../AthContext";
import styles from "./TournamentCardButton.module.css";
import ModalWith2Buttons from "../ModalWith2Buttons/ModalWith2Buttons";
import ModalWith1Button from "../ModalWith1Button/ModalWith1Button";

const TournamentCardButton = ({
  tournamentId,
  isSubscribed,
  onSub,
  remainingTime,
  specialQuizSecondsToStart,
  isSpecialQuiz,
  isSpecialQuizCompleted,
  specialQuizId,
}) => {
  const navigate = useNavigate();
  const loginExpired = useLoginExpired();
  const { t } = useTranslation();
  const { loggedIn } = useAuth();
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal open status
  const [notificationModalOpen, setNotificationModalOpen] = useState(false); // State to manage notification modal open status
  const [specialQuizModal, setSpecialQuizModal] = useState(false); //State to manage special quiz modal open status
  const [tourFull, setTourFull] = useState(false); // State to manage if the tournament is full
  const [noTicket, setNoTicket] = useState(false); // State to manage if the user has no ticket

  const content = {
    mainTitle: noTicket
      ? t("tournamentCard.tournamentCardButton.popUps.mainText.noTickets")
      : tourFull
      ? t("tournamentCard.tournamentCardButton.popUps.mainText.tourFull")
      : isSubscribed
      ? t("tournamentCard.tournamentCardButton.popUps.mainText.alreadySub")
      : t("tournamentCard.tournamentCardButton.popUps.mainText.successSub"),
    secondaryTitle: noTicket
      ? t(
          "tournamentCard.tournamentCardButton.popUps.secondaryText.storeRedirect"
        )
      : tourFull
      ? t("tournamentCard.tournamentCardButton.popUps.secondaryText.full")
      : t(
          "tournamentCard.tournamentCardButton.popUps.secondaryText.instruction"
        ),
  };

  const convertTimeToSeconds = (timeString) => {
    if (!timeString) return 0;

    const timeUnits = timeString.split(" ");
    let totalSeconds = 0;

    timeUnits.forEach((unit) => {
      const value = parseInt(unit.slice(0, -1), 10);
      if (unit.endsWith("h")) {
        totalSeconds += value * 3600;
      } else if (unit.endsWith("m")) {
        totalSeconds += value * 60;
      } else if (unit.endsWith("s")) {
        totalSeconds += value;
      }
    });

    return totalSeconds;
  };

  const remainingTimeInSeconds = convertTimeToSeconds(remainingTime);

  const handleTournamentButtonClick = async () => {
    // if not logged in go forms
    if (!loggedIn) {
      navigate("/forms");
      return;
    }
    try {
      const response = await subscribeTournament(tournamentId);
      //console.log(response);
      if (response.code === 0) {
        //console.log("Subscribed to tournament");
        onSub(true);
        // If there is less than 5 minutes remaining, go directly to the tournament
        if (remainingTimeInSeconds <= 300) {
          localStorage.setItem("tournamentId", tournamentId);
          navigate("/tournament");
        } else {
          //console.log("Subscribed to tournament");
          setNotificationModalOpen(true);
        }
      }
    } catch (error) {
      if (error.response?.status === 400) {
        if (error.response.data.error === t("tourSubscribe.alreadySub")) {
          if (remainingTimeInSeconds <= 300) {
            localStorage.setItem("tournamentId", tournamentId);
            navigate("/tournament");
          } else {
            //console.log("Already Subscribed to tournament");
            setNotificationModalOpen(true);
          }
        } else if (error.response.data.error === t("tourSubscribe.tourFull")) {
          //console.log("Tournament is full");
          setTourFull(true);
          setNotificationModalOpen(true);
        } else if (error.response.data.error === t("tourSubscribe.noTickets")) {
          //console.log("Not enough tickets");
          setNoTicket(true);
          setNotificationModalOpen(true);
          //navigate("/store");
        }
      } else if (error.response?.status === 401) {
        console.log("unauthorized");
        loginExpired();
      } else {
        console.error("Error in subscribing to tournament:", error);
      }
    }
  };

  const handleSpecialQuizButtonClick = () => {
    localStorage.removeItem("specialQuizId");
    localStorage.setItem("specialQuizId", specialQuizId);
    navigate("/specialQuiz");
  };

  const handleButtonClick = () => {
    if (specialQuizSecondsToStart <= 0 || isSpecialQuizCompleted) {
      setSpecialQuizModal(true);
    } else if (specialQuizSecondsToStart > 0 && !isSpecialQuizCompleted) {
      setModalOpen(true);
    }
    if (
      remainingTimeInSeconds <= 0 ||
      (remainingTimeInSeconds <= 10 && !isSubscribed)
    )
      return;
    if (isSubscribed) return handleTournamentButtonClick();
    setModalOpen(true);
  };

  return (
    <>
      {/* Render modals using portals */}
      {notificationModalOpen &&
        ReactDOM.createPortal(
          <ModalWith1Button
            mainTitle={content.mainTitle}
            secondaryTitle={content.secondaryTitle}
            closeButtonTitle={t("generic.ok")}
            onClick={() => {
              // If the user has no ticket, go to store
              if (noTicket) {
                navigate("/store");
              } else {
                setNotificationModalOpen(false);
              }
            }}
          />,
          document.body // Append modal to body
        )}
      {modalOpen &&
        ReactDOM.createPortal(
          <ModalWith2Buttons
            mainTitle={t(
              isSpecialQuiz
                ? "tournamentCard.tournamentCardButton.specialQuiz.popUps.bodyConfirmation"
                : "tournamentCard.tournamentCardButton.popUps.mainText.ticketConfirmation"
            )}
            closeLeftButtonTitle={t("generic.no")}
            closeRightButtonTitle={t("generic.yes")}
            onPressRight={() => {
              setModalOpen(false);
              isSpecialQuiz
                ? handleSpecialQuizButtonClick()
                : handleTournamentButtonClick();
            }}
            onPressLeft={() => {
              setModalOpen(false);
            }}
          />,
          document.body // Append modal to body
        )}
      {specialQuizModal &&
        ReactDOM.createPortal(
          <ModalWith1Button
            mainTitle={t(
              "tournamentCard.tournamentCardButton.specialQuiz.popUps.title"
            )}
            secondaryTitle={
              isSpecialQuizCompleted
                ? t(
                    "tournamentCard.tournamentCardButton.specialQuiz.popUps.bodyAlreadyPlayed"
                  )
                : t(
                    "tournamentCard.tournamentCardButton.specialQuiz.popUps.bodyExpired"
                  )
            }
            closeButtonTitle={t("generic.ok")}
            onClick={() => {
              setSpecialQuizModal(false);
            }}
          />,
          document.body // Append modal to body
        )}
      <Button
        additionalClass={
          (isSpecialQuiz && specialQuizSecondsToStart <= 0) ||
          (isSpecialQuiz && isSpecialQuizCompleted) ||
          (!isSpecialQuiz && remainingTimeInSeconds === "Started")
            ? styles.playButtonCompleted
            : remainingTimeInSeconds <= 300 && isSubscribed
            ? styles.playButtonSuccess
            : styles.playButton
        }
        onClick={handleButtonClick}
      >
        <Typography
          variant="body1"
          fontWeight="font500"
          color="colorBlack"
          additionalClass={styles.buttonText}
        >
          {isSpecialQuiz && isSpecialQuizCompleted
            ? t("tournamentCard.tournamentCardButton.specialQuiz.alreadyPlayed")
            : isSpecialQuiz && specialQuizSecondsToStart <= 0
            ? t("tournamentCard.tournamentCardButton.specialQuiz.expired")
            : isSpecialQuiz && specialQuizSecondsToStart >= 0
            ? t("tournamentCard.tournamentCardButton.specialQuiz.playNow")
            : null}
          {!isSpecialQuiz
            ? remainingTimeInSeconds <= 0
              ? t("tournamentCard.tournamentCardButton.started")
              : isSubscribed
              ? remainingTimeInSeconds <= 300
                ? t("tournamentCard.tournamentCardButton.subLess5")
                : t("tournamentCard.tournamentCardButton.subMore5")
              : remainingTimeInSeconds <= 300
              ? t("tournamentCard.tournamentCardButton.noSubLess5")
              : t("tournamentCard.tournamentCardButton.noSubMore5")
            : null}
        </Typography>
      </Button>
    </>
  );
};

export default TournamentCardButton;
