import React from "react";
import Typography from "../../atoms/Typography/Typography";
import styles from "./LeaderboardHeadings.module.css";
import Icon from "../../atoms/Icon/Icon";
import { useAuth } from "../../../AthContext";

const LeaderboardHeadings = ({ leaderboardHeadings, isMobile, gameMode }) => {

  const {translateText} = useAuth();

  return (
    <div className={`${gameMode !== "specialQuizzesWeekly" ? styles.leaderboardHeadings : styles.leaderboardSpecialHeading}`} >
      {gameMode === "friends" || gameMode === "global" ? (
        leaderboardHeadings.map((entry, index) => (
          <div className={styles.heading} key={index}>
            <Typography
              variant={isMobile ? "caption" : "body2"}
              fontWeight={"font500"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {entry}
            </Typography>
          </div>
        ))
      ) : (
        <div className={styles.heading}>
          <Typography
            variant={isMobile ? "caption" : "body2"}
            fontWeight={"font500"}
            color="colorBlack"
            additionalClass={styles.grey}
          >
            {leaderboardHeadings[0]}
          </Typography>
        </div>
      )}
      <div className={styles.trophy}>
        <Typography
          variant={isMobile ? "caption" : "body2"}
          fontWeight={"font500"}
          color="colorBlack"
          additionalClass={`${styles.grey} ${gameMode === "specialQuizzesWeekly" ? styles.specialHeading : ""}`}        >
          {gameMode === "specialQuizzesWeekly" ? translateText("mainNav.profileTab.stats.statistics.correctAnswers") : null}
        </Typography>
        {gameMode !== "specialQuizzesWeekly" && (
          <Icon folder={"other_icons"} name="goldenTrophy" size={24} />
        )}
      </div>
    </div>
  );
};

export default LeaderboardHeadings;
