import React from "react";
import styles from "./LoadingSpinnerLarge.module.css";

const LoadingSpinnerLarge = () => {
  return (
    <div className={styles.LoadingSpinnerLarge}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default LoadingSpinnerLarge;
