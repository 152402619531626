import React from 'react';
import { useLocation } from "react-router-dom";
import styles from "./BridgerPayModal.module.css";

export const BridgePayModal = () => {
  const location = useLocation();
  const { cashierToken } = location.state || {}; // Default to an empty object if no state is passed

  const cashierKey = '4f1e141c-33ee-40e7-a14c-537d478084c0';
  const checkoutUrl = `https://checkout.bridgerpay.com?cashierKey=${cashierKey}&cashierToken=${cashierToken}`;

  return (
    <div className={styles.iframeContainer}>
      <iframe
        className={styles.iframe}
        src={checkoutUrl}
        title="BridgerPay Checkout">
      </iframe>
    </div>

  );
};