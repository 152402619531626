import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./SpecialQuiz.module.css";
import NumberOfQuestions from "../../molecules/NumberOfQuestions/NumberOfQuestions";
import CounterDownBarNumber from "../../organisms/CounterDownBarNumber/CounterDownBarNumber";
import QuestionAndAnswersRealTime from "../../organisms/QuestionAndAnswersRealTime/QuestionAndAnswersRealTime";
import { useLoginExpired } from "../../../customHooks";
import { useSocket } from "../../../SocketContext";
import { useAuth } from "../../../AthContext";
import CongratulationsTemplateSpecialQuiz from "../CongratulationsTemplateSpecialQuiz/CongratulationsTemplateSpecialQuiz";
import { useNavigate } from "react-router-dom";

const SpecialQuiz = () => {
  const [gameActive, setGameActive] = useState(false);
  const [question, setQuestion] = useState("");
  const [questionNo, setQuestionNo] = useState(1);
  const [userAnswer, setUserAnswer] = useState(null);
  const [tournamentConfig, setTournamentConfig] = useState({
    totalQuestions: 10,
    timeToAnswer: 7000,
  });
  // Derived States from tournamentConfig
  const { totalQuestions, timeToAnswer } = tournamentConfig;
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [finalResults, setFinalResults] = useState(null);

  const loginExpired = useLoginExpired();
  const navigate = useNavigate();
  const questionStartTime = useRef(0);
  const latestUserAnswer = useRef(null);
  const { language } = useAuth();
  const { playerInfo } = useAuth();
  const {
    emitNewSpecialQuiz,
    emitSpecialQuizAnswer,
    onSpecialQuizStarted,
    onSpecialQuizQuestion,
    onSpecialQuizCorrectAnswer,
    onSpecialQuizResult,
    offSpecialQuizStarted,
    offSpecialQuizQuestion,
    offSpecialQuizCorrectAnswer,
    offSpecialQuizResult,
  } = useSocket();

  const playerName = playerInfo?.username;
  const specialQuizId = localStorage.getItem("specialQuizId");
  if (!specialQuizId) navigate("/");

  const gameFinalization = useCallback(
    (response) => {
      setFinalResults(response);
      setGameActive(false);
    },
    [setFinalResults]
  );

  const handleUserAnswer = (userAnswer) => {
    if (
      latestUserAnswer.current ||
      Date.now() - questionStartTime.current >= timeToAnswer
    )
      return;

    latestUserAnswer.current = userAnswer;
    setUserAnswer(userAnswer);

    emitSpecialQuizAnswer(
      { answer: userAnswer, specialQuizId: specialQuizId },
      (response) => {
        if (response.code !== 0) loginExpired();
      }
    );
  };

  useEffect(() => {
    emitNewSpecialQuiz(specialQuizId, playerName, (response) => {
      if (response.code === 1) loginExpired();
      if (response.code === 2) navigate("/"); // Redirect to home page if the user has already played
    });

    const setupListeners = () => {
      onSpecialQuizStarted((response) => {
        setGameActive(true);
        // Commented out until implimented in the backend
        setTournamentConfig((prevConfig) => ({
          ...prevConfig,
          timeToAnswer: response.timeToAnswer,
          totalQuestions: response.questionsLength,
        }));
      });
      onSpecialQuizQuestion((response) => {
        setQuestion({
          question: response[`question_${language}`],
          answer1: response[`answer1_${language}`],
          answer2: response[`answer2_${language}`],
          answer3: response[`answer3_${language}`],
          answer4: response[`answer4_${language}`],
        });
        setQuestionNo(response.questionNo);
        questionStartTime.current = Date.now();
      });
      onSpecialQuizCorrectAnswer((response) => {
        setCorrectAnswer(parseInt(response.correctAnswer));
      });
      onSpecialQuizResult(gameFinalization);
    };

    const cleanupListeners = () => {
      offSpecialQuizStarted();
      offSpecialQuizQuestion();
      offSpecialQuizCorrectAnswer();
      offSpecialQuizResult();
      localStorage.removeItem("specialQuizId");
    };

    setupListeners();
    return cleanupListeners;
  }, [
    emitNewSpecialQuiz,
    specialQuizId,
    language,
    gameFinalization,
    onSpecialQuizStarted,
    onSpecialQuizQuestion,
    onSpecialQuizCorrectAnswer,
    onSpecialQuizResult,
    offSpecialQuizStarted,
    offSpecialQuizQuestion,
    offSpecialQuizCorrectAnswer,
    offSpecialQuizResult,
  ]);

  // Logic for automatically answering if the user hasn't responded
  useEffect(() => {
    let answerTimeout;

    if (gameActive && questionNo >= 1) {
      setCorrectAnswer(null);
      setUserAnswer(null);
      latestUserAnswer.current = null;

      // Default timeout in case questionStartTime.current is not promptly updated
      const fallbackTime = questionStartTime.current
        ? timeToAnswer - (Date.now() - questionStartTime.current)
        : timeToAnswer;

      const remainingTime = Math.max(0, fallbackTime); // Ensure remainingTime is non-negative

      answerTimeout = setTimeout(() => {
        if (!latestUserAnswer.current) {
          emitSpecialQuizAnswer(
            { answer: 5, specialQuizId: specialQuizId },
            (data) => {
              if (data.code !== 0) loginExpired();
            }
          );
        }
      }, remainingTime);
    }

    return () => clearTimeout(answerTimeout);
  }, [gameActive, questionNo, emitSpecialQuizAnswer, timeToAnswer]);

  return (
    <div className={styles.questionAnswersContainer}>
      {gameActive ? (
        <>
          <div></div>
          <NumberOfQuestions
            currentQuestionNumber={questionNo - 1}
            totalQuestions={totalQuestions}
          />
          <CounterDownBarNumber
            seconds={timeToAnswer / 1000}
            questionIndex={questionNo}
          />
          <QuestionAndAnswersRealTime
            currentQuestion={question}
            handleUserAnswer={handleUserAnswer}
            userAnswer={userAnswer}
            correctAnswer={correctAnswer}
          />
        </>
      ) : (
        finalResults && (
          <div className={styles.finalResultsContainer}>
            <CongratulationsTemplateSpecialQuiz
              rightAnswers={finalResults.correctCount}
              questionsLength={finalResults.questionsLength}
            />
          </div>
        )
      )}
    </div>
  );
};

export default SpecialQuiz;
